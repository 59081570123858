/* Hero area Section  */
.hero__area {
    width: 100%;
    height: auto;
    background: #f6f9fc;
    box-shadow: inset 0px 16px 15px -3px rgba(0, 0, 0, 0.1);
}
/* categories wise featureSection */
.cate_wise_feature{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin: 20px 0px;
}
.cate_feature{
    width:100%;
    /* height: 250px; */
    padding: 15px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
    /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); */
}
.cate_feature img{
    width: 100%;
    /* object-fit: contain; */
}
/* Service area Section */
.service__item {
    padding: 20px;
    display: flex;
    align-items: center;
    column-gap: 0.7rem;
    /* border-radius: 5px; */
    cursor: pointer;
    margin-bottom: 20px;
}
.service__item span i {
    font-size: 2.2rem;
    background: var(--color-C22026);
    padding: 10px;
    border-radius: 50px;
    color: var(--secondary-color);
    font-weight: 400 !important;
}
.service__item h3 {
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: 600;
}

.service__item p {
    font-size: 0.9rem;
    color: #222;
    margin-top: 5px;
}

/* Trending Products Section */
.section__title {
    border-bottom: 2px solid #c2202669;
    padding-bottom: 6px!important;
    padding-top: 1rem;
}
.btmborder__st {
    align-items: center;
    border-bottom: 1px solid #e2e5ec !important;
}

.timer__count {
    background: var(--primary-color);
    height: auto;
}

.timer__count img {
    width: 70% !important;
    height: 70% !important;
    object-fit: contain;
}

.store__btn {
    background: var(--secondary-color);
    color: var(--primary-color);
}

.store__btn a:hover {
    color: var(--primary-color);
}

.homeSlide ul {
    position: absolute;
    bottom: 20px;
}

.homeSlide ul li {
    border: 2px solid #b8b7b7;
    border-radius: 50%;
    padding: 10px;
}

.homeSlide ul li button {
    background: transparent !important;
    color: transparent !important;
}

.homeSlide ul li button::before {
    font-size: 15px !important;
    color: var(--color-C22026) !important;
    position: absolute;
    top: 2px;
    left: 0;
    margin: auto;
}

.productlistCard {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    background: #ffffff !important;
    margin-top: 25px;
}

.section__title h3 {
    font-weight: 500;
    font-size: 1.2rem;
    border-width: 2px !important;
    color: #383737c7;
}

/* Add Banner Section */
.addbanner_card{
    padding: 15px;
    margin-top: 25px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
    /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); */
}

.account_state, .contact_info {
    color: #9b9b9b!important;
    font-size: 13px;
}

.import_white {
    color: white!important;
}

@media only screen and (max-width: 992px) {
    .hero__content h2 {
        font-size: 2rem;
    }
    .section__title {
        font-size: 1.7rem;
    }
    .counter__img {
        display: none;
    }
    .clock__top-content {
        text-align: center;
    }
    .clock__top__content h4 {
        font-size: .9rem !important;
    }
    .clock__top__content h3 {
        font-size: 1rem !important;
    }
    .count__down-col {
        text-align: center;
    }
}
.paddingsst{
    padding: 0px;
}
.loadcategory{
    width: 100%;
    height: 494px !important;
  }
  .loadslidertop {
    width: 100%;
    height: 400px !important;
}
   
@media only screen and (max-width: 768px) {
    .hero__content h2 {
        font-size: 1.6rem;
    }
    .hero__content p {
        font-size: 0.9rem;
    }
    .buy__btn a {
        font-size: 0.9rem;
    }
    .section__title {
        font-size: 1.4rem;
    }
    .cate_wise_feature{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    } 
}


@media only screen and (max-width: 576px) {
    .hero__content {
        padding-top: 0;
        margin-bottom: 40px;
    }
    .cate_wise_feature{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    } 
    .productlistCard {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 992px) {
    .service__item {
        padding: 10px;
    }
    .service__item span i {
        font-size: 1.8rem;
        padding: 5px;
    }
    .service__item h3 {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .service__item {
        margin-bottom: 1.5rem;
    }
    .service__item span i {
        font-size: 1.5rem;
        padding: 5px;
    }
}

@media only screen and (max-width: 576px) {
    .service__item {
        padding: 20px;
    }
}




