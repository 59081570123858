.navbar {
  display: none;
}
.navbar img{
  height: 60px;
  object-fit: cover;
}
@media (max-width: 575.98px){
  .navbar {
    background-color: var(--color-131921);
    padding: 20px 0;
    display: block;
  }
  
  .navbar > div {
    max-width: 80%;
    padding: 0;
  }
  
  .hamburger-btn {
    outline: none;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 20px;
  }
  
  .nav-menu {
    width: 100%;
    height: 100%;
    background-color: white;
  }
  
  
  .nav-menu li {
    height: 30px;
    width: 100%;
  }
  
  .nav-menu a {
    width: 100%;
    height: 100%;
    align-content: center;
    text-align: center;
    padding: 0 10%;
  }
  .nav-menu a:hover {
    background-color: lightgrey;
  }
}