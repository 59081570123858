.category__img{
    aspect-ratio: 4 / 3;
}
.category__img img{
    /* max-height: 100%; */
    height: 100%;
    object-fit: cover;
}
.category_link a{
    font-size: 14px;
    color: black;
    font-weight: bold;
    padding-top: 10px;
}
.cate__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}
.cate__card{
    padding: 15px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    height: 100%;
    /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); */
}

.cate__card:hover {
    box-shadow: rgba(253, 11, 11, 0.2) 0px 2px 8px 0px;
}


/* .card {
    border-radius: 1px !important;
    margin: 5px;
    padding: 5px;
    outline: 0 !important;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ebe6e6;
} */