.login-feture{
    background-color: #ffffff; 
}
.auth__form {
    width: 32%;
    padding: 40px;
    margin: 0 auto;
    border-radius: 0px;
    box-shadow: 0px 0px 1px 0px;
}
.form__group > .form-control {
    width: 100%;
    color: #212529;
    background: #FFFFFF;
    border-radius: 0px;
    border: 1px solid #0000003b !important;
}
.auth__btn{
    width: 100%;
    background: var(--color-C22026) !important;
    color: var(--secondary-color) !important;
    border-radius: 0px;
    font-weight: 600 !important;
    border: 0;
    padding: 5px;
}
.form__group input[type="file"] {
    cursor: pointer;
    color: var(--secondary-color);
    margin-right: 190px !important;
    border-radius: 5px !important;
}


@media only screen and (max-width: 576px){
    .auth__form{
        width: 50%;
        margin: 0 auto;
        padding: 40px;
        border-radius: 0px;
        box-shadow: 0px 0px 1px 0px;
    }
}

@media only screen and (max-width: 992px){
    .auth__form{
        width: 80%;
        margin: 0 auto;
        padding: 40px;
        border-radius: 0px;
        box-shadow: 0px 0px 1px 0px;
    }
}