
.filter-sidebar{
    background-color: white;
    width: 100%;
    height: auto;
}
.product-sidebar{
    background-color: white;
    width: 100%;
    height: auto;
}

.accordion-item{
    border-radius: 0 !important;
}
.accordion-item button:focus{
    box-shadow: none !important;
    border: inherit !important;
}

.products-filter {
    background: rgba(255, 255, 255, 1) !important;
    padding: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.05);
}

.form-select{
    border-radius: 0 !important;
}
.form-select .option{
    border-radius: 0 !important;
}


.filter__widget select{
    padding: 8px 20px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    border-radius: 5px;
    background: var(--primary-color);
    color: var( --secondary-color);
}

.filter__widget select:focus {
   outline: none !important;
}

.filter__widget select option{
   font-size: 0.9rem;
}

.search__box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 2px;
    cursor: pointer;
}

.search__box input{
    width: 100%;
    border: none;
    outline: none;
    padding: 8px 10px;
}

.search__box span{
    color: var(--primary-color);
}

@media only screen and (max-width: 768px){
    .filter__widget{
        margin-bottom: 30px;
    }

    .filter__widget select{
        padding: 7px 20px;
        font-size: 0.9rem;
        
    }
    
}